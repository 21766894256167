<template>
  <div class="relative h-screen flex flex-col overflow-hidden">
    <!-- Notifications and Free to Paid Banner -->
    <Notifications />
    <FreeToPaid
      v-if="teamOwnerActivePlan && teamOwnerActivePlan.name === 'Free'"
      class="free-to-paid"
    />

    <CommonHeader 
      class="flex-none z-[10]" 
      @toggle-sidebar="toggleSidebar"
    />

    <div class="flex flex-1 overflow-hidden">
      <transition name="fade">
        <div 
          v-if="showSidebar && windowWidth < 1024" 
          class="fixed inset-0 bg-black bg-opacity-50 z-[9] transition-opacity duration-300"
          @click="closeSidebar"
        />
      </transition>
      
      <transition name="slide">
        <CommonSidebar
          v-show="showSidebar"
          :menu-items="menuItems"
          :is-collapsed="isCollapsed"
          class="!fixed h-full z-10 bg-grayCust-50 border-r border-gray-200 transition-all top-[50px] duration-300 ease-in-out custom-height"
          @on-handle-click="handleSidebarClick"
        />
        <!-- :class="teamOwnerActivePlan && teamOwnerActivePlan.name === 'Free' ? 'top-[94px]' : ''" -->
      </transition>

      <div 
        class="flex-1 overflow-hidden transition-all duration-300 ease-in-out relative"
        :class="{ 'ml-[240px]': showSidebar && !isCollapsed && windowWidth >= 1024, 'ml-14': showSidebar && isCollapsed && windowWidth >= 1024 }"
      >
        <div class="h-full overflow-auto custom-scrollbar-v2">
          <div
            class="absolute lg:flex hidden left-6 z-10 cursor-pointer top-[19px] transition-transform duration-300"
            @click="toggleSidebar"
          >
            <img
              src="/images/collapse.svg"
              alt="Toggle Sidebar"
              class="w-5 h-5 transition-transform duration-300"
              :class="{ 'rotate-180': isCollapsed }"
            >
          </div>
          <slot />
        </div>
      </div>
    </div>
    <GlobalModals />
  </div>
</template>

<script setup>
import FreeToPaid from "@/Common/FreeToPaid.vue";
import Notifications from "@/Common/Notifications.vue";
import CommonSidebar from "@/app/Common/Sidebar/CommonSidebar.vue";
import GlobalModals from "@/app/Components/GlobalModals.vue";
import CommonHeader from "@/app/Header/Header.vue";
import { useAppStore } from '@/store/index';
import { wTrans } from 'laravel-vue-i18n';
import { onBeforeUnmount, onMounted, reactive, ref } from 'vue';

const isCollapsed = ref(false);
const showSidebar = ref(true); 
const windowWidth = ref(window.innerWidth);

const menuItems = reactive([
    {
        title: wTrans('sites'),
        image: '/images/staging.svg',
        path: "staging",
        description: wTrans("menu_staging_description"),
        routename: [
          "staging",
          "sites.edit.staging.dashboard",
          "sites.edit.php.config",
          "sites.edit.install.content",
          "sites.edit.run.commands",
          "sites.edit.staging.sites",
          "sites.edit.map.domain",
          "sites.edit.git.deployment",
          "sites.edit.sftp.ssh",
          "sites.edit.performance",
          "sites.edit.page.in.sight",
          "sites.edit.active.integration",
          "sites.edit.security",
          "sites.edit.slow.plugin.detector",
          "sites.edit.remote.scanner",
          //hosting
          "hosting.manager",
          "hosting-sites.dashboard",
          "hosting-sites.map-domain",
          "hosting-sites.run-commands",
          "hosting-sites.sftp-ssh",
          "hosting-sites.backup",
          "hosting-sites.delete-site",
          "hosting-sites.setting",
          "hosting-sites.statistic",
          "hosting-sites.change-plan",
        ],
    },
    {
        title: wTrans('templates'),
        icon: 'OutlineViewGridIcon',
        path: 'templates',
        description: wTrans("menu_template_description"),
        routename: ["templates", "template.edit", "template.graph", "template.sites", "template.edit.setup", "template.edit.gallery", "template.edit.branding", "template.edit.email.collection", "template.edit.security", "template.edit.integrations", "template.edit.advance", "template.monetize", "template.sales", "template.store.page"],
    },
    {
        title: wTrans('manage'),
        image: '/images/manage.svg',
        path: 'connects',
        description: wTrans("menu_connects_description"),
        routename: [
          "connects",
          "connects.edit.dashboard",
          "connects.edit.health",
          "connects.edit.plugin",
          "connects.edit.theme",
          "connects.edit.user",
          "connects.edit.config.manager",
          "connects.edit.sites",
          "connects.edit.reports",
          "connects.edit.scanner",
          "connects.edit.performance",
          "connects.edit.disconnect",
          "connects.edit.uptime",
          "connects.edit.alert-rules",
          "connects.edit.view-logs",
          "connects.edit.bulk",
        ],
    },
    {
        title: wTrans('waas'),
        image: '/images/waas.svg',
        path: 'waas',
        description: wTrans("sell_templates_with_hosting"),
        routename: ["waas"],
    },
    {
        title: wTrans('settings'),
        icon: 'OutlineCogIcon',
        children: [
            {
                title: wTrans('configurations'),
                path: 'configurations',
                routename: ['configurations'],
            },
            {
                title: wTrans('deployments'),
                path: 'deployments',
                routename: ['configurations'],
            },
            {
                title: wTrans('commands'),
                path: 'commands',
                routename: ['commands'],
            }
        ],
    },
    {
      title: wTrans('integrations'),
      icon: 'OutlinePuzzleIcon',
      path: 'integrations',
      routename: ['integrations'],
    }
]);

const appStore = useAppStore();
const teamOwnerActivePlan = ref(null);

const updateSidebarState = () => {
  windowWidth.value = window.innerWidth;
  
  if (windowWidth.value < 1024) {
    isCollapsed.value = false; 
    showSidebar.value = false; 
  } else if (windowWidth.value < 1200) {
    isCollapsed.value = true; 
    showSidebar.value = true; 
  } else {
    isCollapsed.value = false; 
    showSidebar.value = true; 
  }
};

onMounted(async () => {
  await appStore.initialData();
  teamOwnerActivePlan.value = appStore.teamOwnerActivePlan;
  appStore.subscribeAppVersionReleaseEvent();
  updateSidebarState();
  window.addEventListener('resize', updateSidebarState);
});

onBeforeUnmount(() => {
  window.removeEventListener('resize', updateSidebarState);
});

const toggleSidebar = () => {
  if (windowWidth.value >= 1024) {
    isCollapsed.value = !isCollapsed.value;
  } else {
    showSidebar.value = !showSidebar.value;
  }
};

const closeSidebar = () => {
  showSidebar.value = false;
};

const handleSidebarClick = () => {
  if (windowWidth.value < 1024) {
    showSidebar.value = false;
  }
};
</script>

<style scoped>
.h-screen {
  height: 100vh;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.3s ease;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.slide-enter-active,
.slide-leave-active {
  transition: transform 0.3s ease, opacity 0.3s ease;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateX(-100%);
  opacity: 0.5;
}

@media (min-width: 1024px) {
  .slide-enter-from,
  .slide-leave-to {
    transform: translateX(-20px);
    opacity: 0.8;
  }
}
</style>

